import React from "react";

import { LEGAL_DATA } from "data/pages";

import { Section } from "components/legal";
import { LegalPageLayout } from "components/layouts";
import { LayoutContext } from "../../../context";
import { DEFAULT_VALUES } from "../../../context/Layout";
import SEO from "components/SEO";
import pagesMeta from "data/pagesMeta";

const Legal: React.FC = () => {
  const data = LEGAL_DATA.PAGE_CONTENTS.termsOfService;
  const { updateContext } = React.useContext(LayoutContext);
  React.useEffect(() => {
    updateContext({ isFooterButtonSticky: false });

    return () => {
      updateContext(DEFAULT_VALUES);
    };
  }, []);
  return (
    <LegalPageLayout
      title={data.title}
      desc={data.descMarkup}
      lastUpdated={data.lastUpdated}
    >
      <SEO {...pagesMeta.termsOfService} />

      {data.sections.map(({ label, markup }, index) => {
        return (
          <Section key={index} title={label}>
            {markup}
          </Section>
        );
      })}
    </LegalPageLayout>
  );
};

export default Legal;
